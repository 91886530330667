import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ 
        display: 'flex', flexDirection: 'column', overflow: 'hidden',
        background: "linear-gradient(275deg, rgb(66, 165, 245), rgb(72 141 232))",
       }}
    >
      <img alt='swig' src="https://demos.creative-tim.com/otis-kit-pro/static/media/pattern-lines.d6471bace81c3ada86db50ecb4e28f92.svg" className='bg-image' />      
      <Typography variant="h4" sx={{ my: 5 }}>
        Meta Tattva Code of Business Conduct & Ethics
      </Typography>
      <Typography variant="h6" sx={{ my: 4 }}>
        Meta Tattva Systems is committed to making decisions that are consistent with the core values 
        of &nbsp;<b>Personal Integrity, Corporate Values & Ethical Principles </b>.
      </Typography>
      <Grid container sx={{justifyContent: 'center'}}>
        <Box
          component="img"
          src="/images/integrity-honesty-corporation-company-business-honesty.png"
          alt="integrity"
          sx={{ height: 100, width: 100}}
        />
      </Grid>
      <Container sx={{ mt: 5, mb: 10, display: 'flex', position: 'relative' }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Typography>
                  Meta Tattva Systems would endeavour to conduct its business beyond reproach in a consistently legal and ethical manner. 
                  No efforts would be spared to exercise sound judgment in the service of the Company’s reputation as a business leader and good corporate citizen.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Typography>
                Meta Tattva Systems shall uphold the trust reposed by our business partners, clients, and end-user customers in the quality and value of our service offerings. 
                We shall strive to keep our commitments to our clients, negotiate, perform, and market in good faith. In the absence of competition, the Company shall desist from predatory pricing of our products and fairly price our products consistent with the industry norms and commensurate with                   the services provided, affording value to our clients. 
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Typography>
                Our business dealings will be honest, respectful, and fair to generate mutually advantageous relationships and satisfactory outcomes 
                with our stakeholders, being especially vigilant in ensuring that we negotiate contracts fairly and truthfully.

                Meta Tattva Systems will not knowingly use suppliers and sub-vendors who operate in violation of applicable Laws, Regulations and established Business Codes of Ethics.  
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;