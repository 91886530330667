import React from 'react';
import { Box, Typography, ListItem, ListItemText, ListItemIcon, Paper} from '@mui/material';
import {LocalAirport} from '@mui/icons-material';
import './Service.css';



const Service = () => {
  const sections = [
    {
      title: 'Product Development & Customization',
      items: [
        'Custom MSCS Design: Develop camouflage solutions tailored to specific operational needs (e.g., terrain types, climate conditions, and threats).',
        'Integration Services: Integrate MSCS into various platforms, including vehicles, equipment, and facilities.',
        'Adaptive Camouflage: Provide solutions with adaptive properties for dynamic environments.',
      ],
    },
    {
      title: 'Manufacturing & Supply',
      items: [
        'Bulk Manufacturing: Produce large quantities of MSCS for diverse applications.',
        'Rapid Prototyping: Provide quick turnaround prototypes for customer evaluation and testing.',
        'Supply Chain Management: Manage end-to-end delivery, including transportation and logistics.',
      ],
    },
    {
      title: 'Installation & Deployment',
      items: [
        'On-Site Installation: Deploy MSCS on vehicles, infrastructure, or equipment.',
        'Training: Train personnel to install, use, and maintain the camouflage systems effectively.',
      ],
    },
    {
      title: 'Maintenance & Support',
      items: [
        'Repair & Upgrades: Offer repair services and upgrades to enhance the lifespan and performance of MSCS.',
        'Spare Parts Supply: Ensure the availability of spare parts for seamless system operation.',
      ],
    },
    {
      title: 'Testing & Validation',
      items: [
        'Performance Testing: Validate MSCS performance across various environmental and operational conditions (e.g., visual, IR, thermal, and SAR spectrums).',
        'Certification Support: Assist in obtaining regulatory and operational certifications for the products.',
      ],
    },
    {
      title: 'Consultancy & Advisory',
      items: [
        'Stealth Strategy Consulting: Advise customers on optimal deployment strategies for MSCS in their specific operational scenarios.',
        'Threat Assessment: Provide assessments of ISR threats and recommend tailored solutions.',
        'Camouflage Planning: Assist in planning long-term camouflage and concealment strategies.',
      ],
    },
    {
      title: 'Research & Development',
      items: [
        'Innovation Partnerships: Collaborate with customers or research institutions for co-development of next-gen stealth technologies.',
        'Field Testing Support: Offer facilities and expertise for field trials and evaluations.',
      ],
    },
    {
      title: 'Training & Knowledge Transfer',
      items: [
        'Operational Training: Educate military and industrial users on the operational capabilities of MSCS.',
        'Workshops & Seminars: Conduct workshops to demonstrate advancements in stealth technology.',
      ],
    },
    {
      title: 'Lifecycle Support',
      items: [
        'Long-Term Maintenance Contracts: Offer comprehensive service agreements for the lifecycle of MSCS products.',
        'Performance Audits: Periodically assess the performance and efficacy of deployed MSCS.',
      ],
    },
    {
      title: 'Turnkey Solutions',
      items: [
        'End-to-end Anālakṣhya MSCS Stealth Solutions: Provide complete stealth and camouflage solutions, from threat analysis to system deployment and post-deployment support.',
        'Anālakṣhya Multispectral Infrastructure Camouflage: Design and implement MSCS for fixed installations like Bunkers, Command Centres, or critical infrastructure.',
      ],
    },
  ];

  return (

    <div className="service-section">
      <Paper elevation={6}>
      <Box sx={{ padding: 4, backgroundColor: '#f9f9f9' }} className="service-section">
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
          Services Overview
        </Typography>
        {sections.map((section, index) => (
          <div className='services-sub-section'>
            <Typography color="inherit" align="center" variant="h5" marked="center" className='services-sub-section-title'>
              {section.title}
            </Typography>
            <ul style={{ paddingLeft: '1.5rem', marginTop: 0 }}>
              {section.items.map((item, idx) => (
                  <Typography variant="body1" color="text.secondary" key={idx}>
                    <ListItem key={index}>
                      <ListItemIcon>
                        <LocalAirport/>
                      </ListItemIcon>
                      <ListItemText primary={item}
                      />
                    </ListItem>
                  </Typography>
              ))}
            </ul>
          </div>
        ))}
      </Box>
      </Paper>
    </div>
  );
};

export default Service;