import { Typography, Box } from '@mui/material';
import './Section.css';


export const Mission = () => {
    return (
            <Box
            sx={{
                backgroundColor: '#f4f6f8',
                padding: '30px 0px 60px 0px',
                color: 'black'
            }}>
                <Typography variant="h4" sx={{ my: 5, padding: "15px 0px 5px 0px" }}>
                    Our Mission
                </Typography>
                <Typography color="inherit" align="center" sx={{ my: 4, fontWeight: "bold" }}>
                    To revolutionise the art of stealth through cutting-edge metamaterial technology, <br/> delivering unrivalled multispectral camouflage solutions across all spectrums. 
                </Typography>
                <Typography color="inherit" align="center" sx={{ my: 4, color: "#637481" }}>
                    We are committed to empowering our defence and industrial partners to achieve strategic superiority <br/> and operational excellence in a dynamic world. By safeguarding critical assets and enabling mission success,<br/> we redefine concealment with relentless innovation, collaborative partnerships, and precision-engineered technologies.
                </Typography>
            </Box>
    );
}