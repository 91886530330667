import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { StyledLink } from '../common/StyledLink';
import ProductNav, { ProductNestedList } from './ProductNav';
// import ServiceNav, { ServiceNestedList } from './ServiceNav';
import './Navbar.css';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;

const companyName = "Meta Tattva"


const DrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: "0 10px"
}));

export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // const [openServiceMobile, setServiceOpen] = React.useState(false);
  const [openProductMobile, setProductOpen] = React.useState(false);
  
  // const handleServiceClick = (evt) => {
  //   evt.stopPropagation();
  //   setServiceOpen(!openServiceMobile);
  // };
  
  const handleProductClick = (evt) => {
    evt.stopPropagation();
    setProductOpen(!openProductMobile);
  };

  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <DrawerHeader>
        <Typography variant="h6" sx={{ my: 2 }}>
          {companyName}
        </Typography>
        <IconButton onClick={handleDrawerToggle}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem disablePadding>
          <StyledLink color='black' to='/'>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary='Home'/>
            </ListItemButton>
          </StyledLink>
        </ListItem>
        {/* News */}
        <ListItem disablePadding>
          <StyledLink color='black' to='/news'>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary='News'/>
            </ListItemButton>
          </StyledLink>
        </ListItem>

        {/* New Services */}
        <ListItem disablePadding>
          <StyledLink color='black' to='/services'>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary='Services'/>
            </ListItemButton>
          </StyledLink>
        </ListItem>
        
        {/* Service */}
        {/* <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'left', paddingRight: '40px' }}>
            <ListItemText primary='Service' onClick={handleServiceClick} />
            {openServiceMobile ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem> */}
        {/* Service Nested list */}
        {/* {
          openServiceMobile &&
          <ListItem>
            <Collapse in={openServiceMobile} timeout="auto" unmountOnExit>
              <ServiceNestedList listWidth={200}/>
            </Collapse>
          </ListItem>  
        } */}

        {/* Product */}
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'left', paddingRight: '40px' }}>
              <ListItemText primary='Product' onClick={handleProductClick} />
              {openProductMobile ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
        </ListItem>
        {/* Product Nested list */}
        {
          openProductMobile &&
          <ListItem>
            <Collapse in={openProductMobile} timeout="auto" unmountOnExit>
              <ProductNestedList listWidth={200}/>
            </Collapse>
          </ListItem>  
        }
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex'}}>
      <AppBar component="nav" sx={{bgcolor: '#42a5f5'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block', textAlign: 'left' } }}
          >
            <StyledLink color="white" to='/'>
              {companyName}
            </StyledLink>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <StyledLink color="white" to='/'>
                <Button sx={{ color: '#fff', padding: '0 50px' }}>
                  <span className='hover-underline-animation'>Home</span>
                </Button>
            </StyledLink>  
            <StyledLink color="white" to='/news'>
                <Button sx={{ color: '#fff', padding: '0 50px' }}>
                  <span className='hover-underline-animation'>News</span>
                </Button>
            </StyledLink> 
            <StyledLink color="white" to='/services'>
                <Button sx={{ color: '#fff', padding: '0 50px' }}>
                  <span className='hover-underline-animation'>Services</span>
                </Button>
            </StyledLink>   
            {/* <ServiceNav/> */}
            <ProductNav/>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            onBackdropClick: handleDrawerToggle
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
