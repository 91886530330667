import { Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect } from 'react';
import './App.css';
import Home from "./components/home/Home";
import DrawerAppBar from "./components/navbar/Navbar";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import Avionics from "./components/product/Avionics";
// import Hardware from "./components/service/Hardware";
import Product from "./components/product/Product";
import Service from "./components/service/Service";
import News from "./components/news/News";
// import Integration from "./components/service/Integration";
// import Repair from "./components/service/Repair"
// import Obso from "./components/service/Obso"
// import Btp from "./components/service/Btp";
import Camouflage from "./components/product/Camouflage";
import Supply from "./components/product/Supply";
import ReactGA from 'react-ga4';


function App() {
  ReactGA.initialize(process.env.REACT_APP_GA_API_KEY);
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: `Page: ${location.pathname + location.search}` });
  }, [location]);

  return (
    <div className="App">
      <DrawerAppBar/>
      <Box component="main" sx={{ p: 0 }}>
        <Toolbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="news" element={<News/>}/>
          <Route path="services">
            <Route index element={<Service />} />
            {/* <Route path="hardware" element={<Hardware />}/>
            <Route path="integration" element={<Integration />}/>
            <Route path="btp" element={<Btp />}/>
            <Route path="repair" element={<Repair />}/>
            <Route path="obso" element={<Obso />}/> */}
          </Route>
          <Route path="products">
            <Route index element={<Product />} />
            {/* <Route path="avionics" element={<Avionics />}/> */}
            <Route path="camouflage" element={<Camouflage />}/>
            <Route path="supply" element={<Supply />}/>
          </Route>
        </Routes>
      </Box>
    </div>
  );
}

export default App;
