import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Card, CardContent, CardMedia, Typography, Box, CardActions, Button } from '@mui/material';
import { Tweet } from 'react-tweet'
import YouTube from 'react-youtube';
import './NewsStyle.scss'

export default function News() {
  return (
    <div>
        <TwitterGallery tweets={tweetsData}/>
        <VideoGallery videos={videosData} />
        <NewsGallery newsData={newsData} />
        <ImagesGallery images={imagesData}/>
    </div>
  );
}

export const TwitterGallery = ({ tweets }) => {
    return (
        <div>
            <Typography variant="h4" sx={{ my: 5 }}>
                In the spotlight
            </Typography>
            <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                padding: 2,
                justifyContent: "flex-start",
            }}>
                {tweets.map((tweet) => (
                    <div className='news-card' key={tweet.id}>
                        <Tweet id={tweet.id}/>
                    </div>
                ))}
            </Box>
        </div>
    )
}

export const NewsGallery = ({ newsData }) => {
    return (
        <div>
            <Typography variant="h4" sx={{ my: 5 }}>
                News-room
            </Typography>
            <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                padding: 2,
                justifyContent: "space-between",
            }}>
                {newsData.map((news) => (
                    <Card sx={{ maxWidth: 350 }}>
                        <CardMedia
                            component="img"
                            height={200}
                            sx={{ objectFit: "contain" }}
                            image={news.image}
                            title="image-title"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {news.title}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {news.summary}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button href={news.href} target="_blank" size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                ))}
            </Box>
        </div>
    )
}

export const ImagesGallery = ({ images }) => {
    return (
        <div>
            <Typography variant="h4" sx={{ my: 5 }}>
                Gallery
            </Typography>
            <Box sx={{ overflowY: 'scroll' }}>
                <ImageList variant="masonry" cols={3} gap={8}>
                    {images.map((item) => (
                    <ImageListItem key={item.img}>
                        <img
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        </div>
    );
}

export const VideoGallery = ({ videos }) => {
    return (
      <div>
      <Typography variant="h4" sx={{ my: 5 }}>
          Videos
      </Typography>
        <Box
        sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            padding: 2,
            justifyContent: "space-between",
        }}>
          {videos.map((video, index) => (
              <Card key={index} className='youtube-video-card'>
                {
                    video.youtubeId 
                    ? <YouTube videoId={video.youtubeId } />
                    : <CardMedia
                      component="video"
                      src={video.videoUrl}
                      title={video.title}
                      height={300}
                      controls/>
                }
              </Card>
          ))}
        </Box>
      </div>
    );
  };

const newsData = [
    {
        image: "https://www.hindustantimes.com/ht-img/img/2024/11/26/550x309/For-representation-only--HT-FIle-Photo-_1732650874919.jpg",
        title: "IIT-K develops tech making military hardware near invisible",
        summary: "This innovative technology is designed to make military vehicles, aircraft, and other equipment nearly invisible to radar systems",
        href: "https://www.hindustantimes.com/cities/others/iitk-develops-tech-making-military-hardware-near-invisible-101732650882984.html"
    },
    {
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwORHG6oxAp6RcTnZZzID1G_ZN6xOps6Xt2g&s",
        title: "IIT Kanpur unveils Analaksya stealth tech to make objects invisible to radar",
        summary: "IIT Kanpur's Anālakṣhya Metamaterial Surface Cloaking System (MSCS) is an indigenous defence stealth technology that absorbs radar waves making objects almost invisible...",
        href: "https://www.indiatoday.in/education-today/news/story/iit-kanpur-analaksya-cloaking-system-defence-stealth-technology-make-objects-invisible-to-radar-2640990-2024-11-27"
    },
    {
        image: "https://static.toiimg.com/photo/92222747.cms",
        title: "IIT-K unveils metamaterial that evades radar detection",
        summary: "Kanpur: Taking forward PM Narendra Modi's vision of self-reliance in defence manufacturing, the Indian Institute of Technology, Kanpur (IIT-K) on Tuesday announced a ...",
        href: "https://timesofindia.indiatimes.com/city/kanpur/iit-k-develops-groundbreaking-metamaterial-for-radar-evasion-in-defence/articleshow/115706984.cms"
    },
    {
        image: "https://english.cdn.zeenews.com/static/public/zee-news-new-logo.jpg",
        title: "DNA: IIT Kanpur Develops ‘Invisible’ Shield for Indian Army!",
        summary: "DNA: IIT Kanpur’s innovation has led to the creation of a meta-material cloak that makes Indian soldiers undetectable to enemy radars. Learn how this tech will give a...",
        href: "https://zeenews.india.com/video/news/dna-iit-kanpur-develops-invisible-shield-for-indian-army-2825453.html"
    },
    {
        image: "https://bsmedia.business-standard.com/include/_mod/site/html5/images/business-standard-logo.png",
        title: "IIT Kanpur develops revolutionary tech to make aircraft invisible to radar",
        summary: "High-technology cooperation between indigenous defence industry and academia has begun yielding results. On Tuesday, Indian Institute of Technology Kanpur (IIT-K) ...",
        href: "https://www.business-standard.com/external-affairs-defence-security/news/iit-kanpur-develops-revolutionary-tech-to-make-aircraft-invisible-to-radar-124112701035_1.html"
    },
    {
        image: "https://images.news9live.com/wp-content/uploads/2024/11/iit-kanpur-cloaking-system.png?q=50&w=1200",
        title: "Invisible to Radar? IIT Kanpur’s Breakthrough Cloaking Technology Explained",
        summary: "IIT Kanpur has developed Anālakṣhya, a cloaking technology designed to make military equipment invisible to radar detection. This breakthrough in stealth technology ...",
        href: "https://www.news9live.com/technology/tech-news/invisible-to-radar-iit-kanpurs-breakthrough-cloaking-technology-explained-2760459/amp"
    },
    {
        image: "https://img-cdn.thepublive.com/fit-in/360x280/filters:format(webp)/wion/media/agency_attachments/2024/12/13/2024-12-13t071548098z-wionlogo.webp",
        title: "Breakthrough technology makes radars go blind, to boost India’s national security",
        summary: "In a major boost to India’s defence capabilities, the Indian Institute of Technology (IIT)-Kanpur has developed a path-breaking stealth technology called Anālakṣhya Metamaterial Surface Cloaking System (AMSCS)...",
        href: "https://www.wionews.com/india-news/breakthrough-technology-makes-radars-go-blind-to-boost-indias-national-security-779742"
    },
    {
        image: "https://thebetterindia.com/wp-content/themes/thebetterindia/build/images/logo.svg",
        title: "IIT-K Creates ‘Metamaterial’ That Can Protect Soldiers From Detection by Enemy Radars!",
        summary: "In a significant development, researchers at IIT-Kanpur have developed “textile-based metamaterials for radar absorption that can be used as uniforms for soldiers and skirtings or coverings for ground vehicles,”...",
        href: "https://thebetterindia.com/166010/iit-kanpur-india-innovation-invention-army-stealth-news/"
    },
]

const tweetsData = [
    {
        id: "1861453722909581773"
    },
    {
        id: "1069574911629250560"
    }
]

const videosData = [
    {
        videoUrl: 'https://www.w3schools.com/html/mov_bbb.mp4',
        youtubeId: 'z8WaCsJalNI'
    },
    {
        videoUrl: 'https://youtu.be/kENyDGTeyrw?t=14',
        youtubeId: 'kENyDGTeyrw'
    },
    {
        videoUrl: 'https://youtu.be/YvkoMTl3Y_Y?t=5s',
        youtubeId: 'YvkoMTl3Y_Y'
    },
];

const imagesData = [
  {
    img: '/news-room-images/business-standard.png',
    title: 'business-standard',
  },
  {
    img: '/news-room-images/news-nine.png',
    title: 'news-nine',
  },
  {
    img: '/news-room-images/praveen-bhatt-news.png',
    title: 'main',
  },
  {
    img: '/news-room-images/tech-image.png',
    title: 'tech',
  },
  {
    img: '/news-room-images/the-better-india.png',
    title: 'better',
  },
  {
    img: '/news-room-images/twitter-crew.png',
    title: 'crew',
  },
];